import axios from '../../plugins/axios.js';
import utils from '../../utils/utils';
import { event } from '../../utils/event';
import * as Sentry from '@sentry/vue';

const state = () => ({
  fetching: false,
  buildings: [],
  defaultAreas: [],
  commonAreaTypes: [],
});

const getters = {
  buildings: (state) => {
    return state.buildings;
  },
  getBuildingById: (state) => (buildingId) => {
    return state.buildings.find((building) => {
      return building.id === buildingId;
    });
  },
  defaultAreas: (state) => {
    return state.defaultAreas;
  },
  commonAreaTypes: (state) => {
    return state.commonAreaTypes;
  },
};

const actions = {
  get(context, token) {
    let url = '/api/v1/core/buildings?onlyActive=true';
    if (token) {
      url = '/api/v1/external/core/buildings?onlyActive=true&token=' + token;
    }
    return axios.get(url).then((response) => {
      // sort buildings by name_formal
      const buildings =
        response.data?.payload.toSorted((a, b) => {
          // Extract the numeric part of the strings using regular expressions
          const numA = a.name_formal.match(/#(\d+)/);
          const numB = b.name_formal.match(/#(\d+)/);

          if (numA && numB) {
            // Compare the numeric parts
            if (parseInt(numA[1]) < parseInt(numB[1])) {
              return -1;
            } else if (numA > numB) {
              return 1;
            }
          } else {
            // If the numeric parts are equal, sort based on the entire string
            return a.name_formal.localeCompare(b.name_formal);
          }
        }) ?? [];
      context.commit('updateBuildings', buildings);
      return context.state.buildings;
    });
  },

  getWithId(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/core/buildings' + encodeURI(queryString)).then((response) => {
      return response.data.payload[0];
    });
  },

  getBuildingTenants(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/core/building-tenants' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },
  getWithParams(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/core/buildings' + encodeURI(queryString)).then((response) => {
      if (response.data.status === 'error') {
        event.emit('alert', response.data.payload.message, '', 'red');
        return null;
      }
      return response.data.payload;
    });
  },
  async getDefaultAreas(context) {
    if (context.state.defaultAreas.length === 0) {
      return axios.get('/api/v1/core/building/common-areas/default').then((response) => {
        context.commit('updateDefaultAreas', response.data.payload);
        return response.data.payload;
      });
    }

    return context.state.defaultAreas;
  },
  getCommonAreasByFloorId(context, floorId) {
    return axios.get(`/api/v1/core/building/common-areas-by-floor-id?floor-id=${floorId}`).then((response) => {
      return response.data.payload;
    });
  },
  getCommonAreaTypes(context) {
    if (context.state.commonAreaTypes.length === 0) {
      return axios.get(`/api/v1/core/building/common-area-types`).then((response) => {
        context.commit('updateCommonAreaTypes', response.data.payload);
        return response.data.payload;
      });
    }
  },
  getCommonAreaTypesByFloorId(context, floorId) {
    return axios.get(`/api/v1/core/building/common-area-types-by-floor-id?floor-id=${floorId}`).then((response) => {
      return response.data.payload;
    });
  },
  updateCommonArea(context, { area }) {
    return axios.post('/api/v1/core/building/common-areas', { area }).then((response) => {
      if (response.data.status === 'error' || response.status !== 200) {
        if (response.data.payload.messages) {
          event.emit('alert', response.data.payload.messages[0], '', 'red');
        } else {
          Sentry.captureException(new Error('unexpected error: ' + response.data));
          event.emit('alert', 'an unexpected error has occurred. please try again.', '', 'red');
        }
      }
      event.emit('alert', 'new area successfully saved', '', 'green');
      return response.data.payload.area;
    });
  },
  updateCommonAreaFloor(context, { area, floor_ids }) {
    return axios
      .post('/api/v1/core/building/common-areas/associate-floor', {
        floor_ids,
        area_id: area.id,
      })
      .then((response) => {
        if (response.data.status === 'error' || response.status !== 200) {
          event.emit('alert', 'invalid floor or area id. Please refresh the page and try again.', '', 'red');
          return null;
        }
        event.emit('alert', 'updated successfully', '', 'green');

        return response.data.payload;
      });
  },
  getAuditLog(context, buildingId) {
    return axios.get(`/api/v1/building/${buildingId}/audit-log`).then((res) => res.data.payload);
  },
  getJobSiteAssignments(context, buildingId) {
    return axios.get(`/api/v1/building/${buildingId}/job-assignments`).then((res) => res.data.payload);
  },
  getFiles(context, buildingId) {
    return axios.get(`/api/v1/core/building/${buildingId}/files`).then((res) => res.data.payload);
  },
  getUserAssignments(context, buildingId) {
    return axios.get(`/api/v1/building/${buildingId}/job-assignments`).then((res) => res.data.payload);
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateBuildings(state, buildings) {
    state.buildings = buildings;
  },

  updateDefaultAreas(state, areas) {
    state.defaultAreas = areas;
  },

  updateCommonAreaTypes(state, types) {
    state.commonAreaTypes = types;
  },

  sortBuildings(state, { column, order }) {
    state.buildings.sort(function (a, b) {
      // suffix of '_at' assume date
      if (column.indexOf('_at') > -1) {
        a = moment(a[column], 'MM/DD/YYYY').unix() || 0;
        b = moment(b[column], 'MM/DD/YYYY').unix() || 0;
      } else {
        a = a[column];
        b = b[column];
      }

      return (a === b ? 0 : a > b ? 1 : -1) * order;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
