import axios from '../../plugins/axios.js';

export default {
  namespaced: true,
  state: () => ({
    topics: [],
    briefings: [],
    topicFiles: [],
    courses: [],
  }),
  getters: {
    topic: (state) => (topicId) => {
      topicId = parseInt(topicId);
      const recursiveSearch = (topic) => {
        let matchedTopic = topic.id === topicId ? topic : null;

        if (!matchedTopic && topic.children) {
          for (let i = 0; i < topic.children.length; i++) {
            matchedTopic = recursiveSearch(topic.children[i]);
            if (matchedTopic) {
              break;
            }
          }
        }

        return matchedTopic;
      };

      let matchedTopic = null;
      for (let i = 0; i < state.topics.length; i++) {
        matchedTopic = recursiveSearch(state.topics[i]);
        if (matchedTopic) {
          break;
        }
      }

      if (!matchedTopic) {
        console.warn('could not find a matching topic for id', topicId);
      }

      return matchedTopic;
    },
    translatedCourses: (state, getters) => {
      const courses = state.courses.map((c) => ({ ...c }));
      return courses.map(getters.translatedCourse);
    },
    translatedCourse: (state, getters, rootState) => (course) => {
      const lang = rootState.lang;
      const translation = course.translations.find((t) => t.lang === lang);
      if (translation) {
        course.title = translation.title;
        course.description = translation.description;
      }

      return course;
    },
    activeVideo: (state, getters, rootState) => (course) => {
      const lang = rootState.lang;
      let activeVideo = course.active_video.find((v) => v.lang === lang);
      if (!activeVideo) {
        activeVideo = course.active_video.slice(0, 1);
      }

      return activeVideo;
    },
  },
  mutations: {
    briefings(state, briefings) {
      state.briefings = briefings;
    },
    addBriefing(state, briefing) {
      state.briefings.push(briefing);
    },
    topics(state, topics) {
      state.topics = topics;
    },
    topicFiles(state, files) {
      state.topicFiles = files;
    },
    courses(state, courses) {
      state.courses = courses;
    },
  },
  actions: {
    async getTopics(ctx) {
      const response = await axios.get('/api/v1/library/topics');
      ctx.commit('topics', response.data.payload.topics);
    },
    async addTopic(ctx, topic) {
      const response = await axios.post('/api/v1/library/topic', topic);

      ctx.commit('topics', response.data.payload.topics);
    },
    async deleteTopic(ctx, topicId) {
      const response = await axios.delete('/api/v1/library/topic/' + topicId);
      ctx.commit('topics', response.data.payload.topics);
    },
    async getTopicFiles(ctx, topicId) {
      const response = await axios.get(`/api/v1/library/topic/${topicId}/files`);

      ctx.commit('topicFiles', response.data.payload.files);
    },
    async getBriefings(ctx) {
      const response = await axios.get('/api/v1/library/daily-briefings');

      ctx.commit('briefings', response.data.payload.briefings);
    },
    async getCourses(ctx) {
      const response = await axios.get('/api/v1/library/courses');
      ctx.commit('courses', response.data.payload);
      return response.data.payload;
    },
    async getCourse({ state }, courseId) {
      let course = state.courses.find((c) => c.id === courseId);
      if (!course) {
        const response = await axios.get(`/api/v1/library/course/${courseId}`);
        course = response.data.payload;
      }

      return course;
    },
    async getCoursePublic({ state }, { courseId, orgSlug }) {
      let course = state.courses.find((c) => c.id === courseId);
      if (!course) {
        const response = await axios.get(`/api/v1/${orgSlug}/library/course/${courseId}`);
        course = response.data.payload;
      }

      return course;
    },
    async createCourse(ctx, course) {
      const response = await axios.post('/api/v1/library/course', course);
      const newCourse = response.data.payload;
      ctx.commit('courses', [...ctx.state.courses, newCourse]);
      return newCourse;
    },
    async updateCourse(ctx, course) {
      const response = await axios.put(`/api/v1/library/course/${course.id}`, course);
      const newCourse = response.data.payload;

      // refresh course list
      await ctx.dispatch('getCourses');

      return newCourse;
    },
    deleteCourse(ctx, courseId) {
      return axios.delete(`/api/v1/library/course/${courseId}`);
    },
    async addVideo(ctx, { courseId, video }) {
      if (!(video instanceof FormData)) {
        throw new Error('Video must be an instance of FormData when adding a new video to a course.');
      }

      // upload the video
      const response = await axios.post(`/api/v1/library/course/${courseId}/video`, video, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // refresh course list
      await ctx.dispatch('getCourses');

      return response.data.payload;
    },
  },
};
