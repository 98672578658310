// import { event } from '../../utils/event';

export default () => {
  // let allowed = to.meta.allowed;
  // if (to.meta.allowed instanceof Function) {
  //   allowed = to.meta.allowed();
  // }
  // if (!allowed) {
  //   event.emit('alert', 'You do not have permission to view that page.', '', 'warning');
  //   // user is not allowed to visit this page.
  //   return next(from ?? '/dashboard');
  // }
};
