import { utils } from '../../utils/utils';
import axios from '../../plugins/axios';

const state = () => ({
  fetching: false,
  clients: [],
  inactiveClients: [],
});

const getters = {
  clients: (state) => {
    return state.clients;
  },
  inactiveClients: (state) => {
    return state.inactiveClients;
  },
};

const actions = {
  async get(context) {
    if (context.rootState.settings.settings.clients.canViewClients) {
      return axios.get('/api/v1/core/clients').then((response) => {
        context.commit('updateClients', response.data.payload);
        return response.data.payload;
      });
    }

    return [];
  },
  getInactive(context) {
    // only query areas if we do not already have them.
    axios.get('/api/v1/core/inactive-clients').then((response) => {
      context.commit('updateInactiveClients', response.data.payload);
    });
  },
  getWithId(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/core/clients' + encodeURI(queryString)).then((response) => {
      return response.data.payload[0];
    });
  },
  save(context, client) {
    const formData = new FormData();
    for (let field in client) {
      if (!client.hasOwnProperty(field)) continue;

      formData.append(field, client[field] || '');
    }

    return axios
      .post('/api/v1/core/clients', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data.payload);
  },
  delete(context, clientId) {
    return axios.delete('/api/v1/core/clients/' + clientId).then((response) => response.data);
  },
  restore(context, clientId) {
    return axios.put('/api/v1/core/clients/restore/' + clientId).then((response) => response.data);
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateClients(state, clients) {
    state.clients = clients;
  },
  updateInactiveClients(state, inactiveClients) {
    state.inactiveClients = inactiveClients;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
