<template>
  <v-app>
    <template v-if="showNav">
      <app-nav />
    </template>

    <v-main>
      <router-view />

      <alert />
      <confirm />
      <custom-confirm />
      <loader />
      <new-version />
    </v-main>
  </v-app>
</template>

<script>
import AppNav from './components/Nav.vue';
import Alert from './components/core/Alert.vue';
import CustomConfirm from './components/core/CustomConfirm.vue';
import Confirm from './components/core/Confirm.vue';
import Loader from './components/core/Loader.vue';
import NewVersion from './components/NewVersion.vue';

export default {
  name: 'App',
  components: { AppNav, Alert, Confirm, CustomConfirm, Loader, NewVersion },
  computed: {
    showNav() {
      return this.$route && this.$route.meta.showNav;
    },
  },
};
</script>

<style>
/* GLOBAL STYLES */
th {
  white-space: nowrap;
}
</style>
