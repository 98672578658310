<template>
  <div id="custom-confirm">
    <v-dialog v-model="dialog" persistent min-width="290" max-width="800">
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>{{ contentHtml }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(option, key) in options"
            :key="key"
            variant="flat"
            :class="option.buttonClass"
            @click="respond(option.value)"
          >
            <v-icon v-if="option.buttonIcon" start>{{ option.buttonIcon }}</v-icon>
            {{ option.text }}
          </v-btn>
          <v-btn variant="text" @click="respond(0)">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { event } from './../../utils/event';
export default {
  data() {
    return {
      dialog: false,
      callbackEvent: 'confirm-response',
      title: '',
      contentHtml: '',
      options: {},
      unsubscribe: null,
    };
  },

  mounted() {
    this.unsubscribe = event.on('custom-confirm', (cbEvent, message, options) => {
      if (cbEvent) {
        this.callbackEvent = cbEvent;
      }

      this.contentHtml = message;
      this.options = options;
      this.dialog = true;
    });
  },

  beforeUnmount() {
    if (this.unsubscribe instanceof Function) {
      this.unsubscribe();
    }
  },

  methods: {
    respond(response) {
      this.dialog = false;
      event.emit(this.callbackEvent, response);
    },
  },
};
</script>
