<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <v-btn icon :size="$vuetify.display.smAndDown ? 'small' : 'default'" @click="showSpeed = !showSpeed">
      <v-icon :color="color">{{ icon }}</v-icon>
    </v-btn>
    <span v-if="showSpeed" style="font-size: 9px">{{ speed }}</span>
  </div>
</template>
<script>
export default {
  name: 'OfflineIndicator',
  data: () => ({
    speedMonitorEnabled: true,
    showSpeed: false,
    online: true,
    speeds: {
      bps: 0,
      kbps: 0,
      mbps: 0,
    },
    intervalId: null,
  }),
  computed: {
    icon() {
      return !this.online ? 'mdi-wifi-off' : this.speeds.kbps > 100 ? 'mdi-wifi' : 'mdi-wifi-alert';
    },
    color() {
      return !this.online ? 'red' : this.speeds.kbps > 100 ? 'green' : 'orange';
    },
    speed() {
      if (!this.online) {
        return '0 bps';
      }

      let bestSpeed = this.speeds.mbps > 1 ? 'mbps' : this.speeds.kbps > 1 ? 'kbps' : 'bps';

      return `${this.speeds[bestSpeed]} ${bestSpeed}`;
    },
  },
  mounted() {
    this.checkOnlineStatus();
    window.addEventListener('offline', this.checkOnlineStatus);
    window.addEventListener('online', this.checkOnlineStatus);

    this.startSpeedMonitor();
  },
  beforeUnmount() {
    this.stopSpeedMonitor();
    window.removeEventListener('offline', this.checkOnlineStatus);
    window.removeEventListener('online', this.checkOnlineStatus);
  },
  methods: {
    startSpeedMonitor() {
      if (this.speedMonitorEnabled) {
        this.intervalId = setInterval(this.checkOnlineStatus, 60000);
      }
    },
    stopSpeedMonitor() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    checkOnlineStatus() {
      const download = new Image();
      download.onload = () => {
        this.online = true;

        if (performance === undefined) {
          console.log('Performance API is not supported.');
          return;
        }

        const resources = performance.getEntriesByType('resource');
        resources.forEach((resource) => {
          if (!resource.name.includes('1.png')) {
            return;
          }

          // Response time
          const requestTimeMs = resource.responseEnd - resource.responseStart ?? 1;

          // connection speed
          let bitsLoaded = 381 * 8;
          let duration = requestTimeMs / 1000;
          let bps = (bitsLoaded / duration).toFixed(2);
          let kbps = (bps / 1024).toFixed(2);
          let mbps = (kbps / 1024).toFixed(2);

          this.speeds = {
            bps,
            kbps,
            mbps,
          };

          if (this.intervalId === null) {
            this.startSpeedMonitor();
          }
        });
      };

      download.onerror = () => {
        this.online = false;
        this.speeds = { bps: 0, kbps: 0, mbps: 0 };
        this.stopSpeedMonitor();
      };

      download.src = `/images/1.png?n=${Math.random()}`;
    },
  },
};
</script>
