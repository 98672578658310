const utils = {
  googleMapLink(building) {
    return (
      'https://www.google.com/maps/dir/?api=1&destination=' +
      encodeURIComponent(building.address) +
      ',' +
      encodeURIComponent(building.city) +
      ',' +
      encodeURIComponent(building.state) +
      ' ' +
      encodeURIComponent(building.zip)
    );
  },
  formatQueryString(queryParameters) {
    let queryString = '';
    if (queryParameters) {
      queryString = '?';
      queryString += Object.keys(queryParameters)
        .filter((p) => queryParameters[p] !== null)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(queryParameters[key]))
        .join('&');
    }

    return queryString;
  },
  proper(value) {
    if (!value) return '';
    const separatorRegex = /[.?!] /g;
    const separators = [...value.matchAll(separatorRegex)];
    const sentences = value
      .toString()
      .split(separatorRegex)
      .filter((s) => !!s);

    return sentences
      .map((s, i) => {
        const upperFirstLetter = s.trim().charAt(0).toUpperCase();
        let formattedSentence = upperFirstLetter + s.trim().slice(1);
        if (separators[i]) {
          formattedSentence += separators[i] ?? '';
        }

        return formattedSentence;
      })
      .join(' ');
  },
  capitalize(value) {
    if (!value) return '';
    const words = value.toString().split(' ');
    return words.map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
  },
};

export { utils };

export default utils;
