import { createI18n } from 'vue-i18n';
import Store from '../store';

import es from '../../lang/es.json';
let en = {};
Object.keys(es).forEach((t) => (en[t] = t));
const messages = { en, es };

export default createI18n({
  locale: Store.state.lang,
  allowComposition: true,
  messages,
});
