import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist';

// Modules
import areas from './modules/areas';
import settings from './modules/settings';
import backgroundTypes from './modules/backgroundTypes';
import breadcrumbs from './modules/breadcrumbs';
import buildings from './modules/buildings';
import buildingNotes from './modules/buildingNotes';
import buildingTenants from './modules/buildingTenants';
import clients from './modules/clients';
import closetInspectionQuestions from './modules/closetInspectionQuestions';
import actionLog from './modules/actionLog';
import highTouchPoints from './modules/highTouchPoints';
import inspections from './modules/inspections';
import integrations from './modules/integrations.js';
import jobSiteAccess from './modules/jobSiteAccess';
import jobSiteAssignment from './modules/jobSiteAssignment';
import library from './modules/library';
import specialProjects from './modules/specialProjects';
import specialProjectInvoices from './modules/specialProjectInvoices';
import tasks from './modules/tasks';
import nightlyReports from './modules/nightlyReports';
import files from './modules/files';
import user from './modules/user';
import users from './modules/users';
import vendors from './modules/vendors';
import worldMap from './modules/worldMap';
import organization from './modules/organization';

export const storeKey = 'cora-store';

const vuexSessionStorage = new VuexPersist({
  key: storeKey,
  storage: window.localStorage,
});

const debug = process.env.NODE_ENV !== 'production';

const getters = {
  hasLangCookie() {
    return document.cookie.split(';').some((item) => item.trim().startsWith('lang='));
  },
  langCookieValue() {
    return document.cookie
      .split(';')
      .find((item) => item.trim().startsWith('lang'))
      .split('=')[1];
  },
};

const mutations = {
  updateLocale(state, lang) {
    state.lang = lang;
  },
};

const actions = {
  setLocaleCookie(context) {
    document.cookie = `lang=${context.state.lang}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  },
  async initApplicationData({ dispatch, state }) {
    dispatch('users/get');
    dispatch('buildings/get');
    dispatch('areas/get');

    if (state.settings.settings.clients.canViewClients) {
      dispatch('clients/get');
    }
  },
};

export default createStore({
  state() {
    return {
      lang: 'en',
    };
  },
  getters,
  actions,
  mutations,
  modules: {
    actionLog,
    areas,
    backgroundTypes,
    breadcrumbs,
    buildings,
    buildingNotes,
    buildingTenants,
    clients,
    closetInspectionQuestions,
    files,
    highTouchPoints,
    inspections,
    integrations,
    jobSiteAccess,
    jobSiteAssignment,
    library,
    nightlyReports,
    organization,
    settings,
    specialProjects,
    specialProjectInvoices,
    tasks,
    user,
    users,
    vendors,
    worldMap,
  },
  strict: debug,
  plugins: [vuexSessionStorage.plugin],
});
