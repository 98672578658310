<template>
  <v-menu location="bottom">
    <template #activator="{ props }">
      <v-btn icon :size="$vuetify.display.smAndDown ? 'small' : 'default'" v-bind="props">
        <v-icon>mdi-translate-variant</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(lang, index) in languages" :key="index" @click="updateLang(lang.value)">
        <v-list-item-title>{{ lang.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    languages: [
      { text: 'english', value: 'en' },
      { text: 'español', value: 'es' },
    ],
  }),
  computed: {
    ...mapGetters({
      hasLangCookie: 'hasLangCookie',
      langCookieValue: 'langCookieValue',
    }),
  },
  mounted() {
    // set up a watcher for when the language changes
    this.$store.watch(
      (state) => state.lang,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.$store.dispatch('setLocaleCookie');
          location.reload();
        }
      }
    );

    if (this.hasLangCookie === false) {
      if (this.$store.state.lang !== 'en') {
        this.updateLang(this.$store.state.lang);
      }
    } else {
      this.updateLang(this.langCookieValue);
    }
  },
  methods: {
    updateLang(lang) {
      this.$store.commit('updateLocale', lang);
    },
  },
};
</script>
<style scoped>
.absolute {
  z-index: 10;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
