import { utils } from '../../utils/utils';
import { event } from '../../utils/event';
import axios from '../../plugins/axios.js';

const defaultFilters = {
  building: 0,
  client: 0,
  region: 0,
  area: 0,
  type: 0,
  dateFrom: null,
  dateTo: null,
};

const state = () => ({
  fetching: false,
  reports: [],
  reportTypes: [],
  statusColor: {
    new: '#5491F9',
    pending: '#31CF97',
    finalized: '#96999C',
  },
  statusCode: {
    new: 1,
    pending: 2,
    finalized: 3,
  },
  filters: defaultFilters,
});

const getters = {
  reports: (state) => {
    return state.reports;
  },
  reportTypes: (state) => {
    return state.reportTypes;
  },
  statusColor: (state) => {
    return state.statusColor;
  },
  statusCode: (state) => {
    return state.statusCode;
  },
  filters: (state) => {
    return state.filters;
  },
  getStatusColor: (state) => (code) => {
    const label = Object.entries(state.statusCode).filter((c) => c[1] === code)[0][0];
    return state.statusColor[label];
  },
};

const actions = {
  get(context) {
    // only query buildings if we do not already have them.
    if (context.state.fetching !== true && context.state.reports.length === 0) {
      context.commit('updateFetching', true);
      axios.get('/api/v1/daily-reports').then((response) => {
        context.commit('updateFetching', false);
        context.commit('updateReports', response.data.payload);
      });
    }
  },

  getById(context, params) {
    if (!params.id) {
      console.error('id is required when calling getById');
      return;
    }

    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/daily-reports/' + params.id + encodeURI(queryString)).then((response) => {
      const report = response.data.payload;
      if (!report?.description) {
        report.description = '';
      }

      if (!report.data['general_comments']?.description) {
        report.data['general_comments'].description = '';
      }
      return report;
    });
  },

  getWithParams(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/daily-reports' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },

  save(context, params) {
    if (!params.report || !params.buildingId) {
      console.error('report and building id are required when saving a nightly report.');
      return;
    }

    // send the post request
    return axios.post('/api/v1/daily-reports', {
      nightly_report: params.report,
      building_id: params.buildingId,
    });
  },

  finalize(context, report) {
    return axios
      .post('/api/v1/daily-reports/finalize', {
        currentNightlyReport: report,
      })
      .then((response) => {
        if (response.data.status === 'error') {
          event.emit('alert', response.data.payload.message, '', 'red');
          return null;
        }
      });
  },

  delete(context, reportId) {
    return axios.delete('/api/v1/daily-reports/' + reportId).then((response) => {
      if (response.data.status === 'error') {
        event.emit('alert', response.data.payload.message, '', 'red');
        return null;
      }
    });
  },

  deleteFinal(context, reportId) {
    return axios.delete('/api/v1/daily-reports/finalize/delete/' + reportId).then((response) => {
      if (response.data.status === 'error') {
        event.emit('alert', response.data.payload.message, '', 'red');
        return null;
      }
    });
  },

  getReportTypes(context) {
    if (context.state.reportTypes.length === 0) {
      axios.get('/api/v1/daily-reports/get-types').then((response) => {
        if (response.data.status === 'error') {
          event.emit('alert', response.data.payload.message, '', 'red');
          return null;
        }

        context.commit('updateReportTypes', response.data.payload);
      });
    }
  },

  getTableData(context, params) {
    let queryString = '';
    if (params) {
      if (params.status) {
        params.status = Array.isArray(params.status) ? params.status.join(',') : params.status;
      }
      queryString = utils.formatQueryString(params);
    }
    return axios.get('/api/v1/daily-reports/table-data' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateReports(state, reports) {
    state.reports = reports;
  },

  updateReportTypes(state, types) {
    state.reportTypes = types;
  },

  updateFilters(state, filters) {
    state.filters = filters;
  },

  resetFilters(state) {
    state.filters = defaultFilters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
