<template>
  <v-img v-if="!!profilePhotoId || !!imgSource" :key="imgKey" :src="imageSource" :alt="userInitials" cover />
  <span v-else class="text-white" :style="{ fontSize: initialsFontSize + 'px' }">
    {{ userInitials }}
  </span>
</template>
<script>
export default {
  props: {
    imgSource: {
      type: String,
      default: null,
    },
    imgKey: {
      type: Number,
      default: 0,
      required: false,
    },
    profilePhotoId: {
      type: Number,
      default: 0,
    },
    profileUserName: {
      type: String,
      default: null,
    },
    avatarSize: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    imageSource() {
      if (this.imgSource) {
        return this.imgSource;
      } else if (this.profilePhotoId) {
        return `/file/get/${this.profilePhotoId}?request=${this.imgKey}`;
      }

      return '';
    },
    userInitials() {
      return this.profileUserName
        ? this.profileUserName
            .split(' ')
            .filter((name, i, arr) => i === 0 || i === arr.length - 1)
            .map((name) => name[0])
            .join('')
        : null;
    },
    initialsFontSize() {
      return this.avatarSize * 0.5;
    },
  },
};
</script>
