import axios from '../../plugins/axios.js';
const state = () => ({
  apiUrl: '/api/v1/job-site-assignment',
  assignments: [],
});

const getters = {};

const mutations = {
  setAssignments(state, assignments) {
    state.assignments = assignments;
  },
};

const actions = {
  get({ commit }) {
    return axios.get('/api/v1/job-site-assignments').then((response) => {
      commit('setAssignments', response.data.payload);
      return response.data.payload;
    });
  },
  getById: async ({ state }, { id }) => {
    let assignment = state.assignments.find((a) => a.id === id);
    if (!assignment) {
      assignment = await axios.get('/api/v1/job-site-assignment/' + id).then((response) => response.data.payload);
    }

    return assignment;
  },
  getAssignedUsers(ctx, { assignmentId }) {
    return axios.get(`/api/v1/job-site-assignment/${assignmentId}/users`).then((response) => {
      return response.data.payload;
    });
  },
  sync(ctx, { userId, buildingIds }) {
    return axios.put(`/api/v1/user/${userId}/job-site-access`, { buildingIds });
  },
  save({ dispatch }, assignment) {
    if (assignment.id) {
      return dispatch('update', assignment);
    } else {
      return dispatch('create', assignment);
    }
  },
  create({ state }, assignment) {
    return axios.post(state.apiUrl, assignment).then((response) => {
      this.assignment = response.data.payload;
    });
  },
  update({ state }, assignment) {
    return axios.put(state.apiUrl + `/${assignment.id}`, assignment).then((response) => {
      this.assignment = response.data.payload;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
