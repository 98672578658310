<template>
  <v-dialog v-model="open" :scrim="false" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{ loadingText.proper() }}
        <v-progress-linear indeterminate class="my-2"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { event } from '../../utils/event';
export default {
  data() {
    return {
      open: false,
      loadingText: '',
      unsubscribe: null,
    };
  },
  mounted() {
    this.unsubscribe = event.on('loading', (loading) => {
      if (loading) {
        if (typeof loading === 'string') {
          this.loadingText = loading;
        }
        this.open = true;
      } else {
        this.open = false;
      }
    });
  },

  beforeUnmount() {
    if (this.unsubscribe instanceof Function) {
      this.unsubscribe();
    }
  },
};
</script>
