<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 24 24"
    xml:space="preserve"
  >
    <g id="low">
      <path
        class="st0"
        d="M5,23L5,23c-1.66,0-3-1.64-3-3.67V4.67C2,2.64,3.34,1,5,1h0c1.66,0,3,1.64,3,3.67V12v7.33
        C8,21.36,6.66,23,5,23z"
      />
    </g>
    <path
      id="medium"
      class="st0"
      d="M12,23L12,23c-1.66,0-3-1.34-3-3V4c0-1.66,1.34-3,3-3h0c1.66,0,3,1.34,3,3v16
	C15,21.66,13.66,23,12,23z"
    />
    <path
      id="high"
      class="st1"
      d="M19,23L19,23c-1.66,0-3-1.34-3-3V4c0-1.66,1.34-3,3-3h0c1.66,0,3,1.34,3,3v16
	C22,21.66,20.66,23,19,23z"
    />
  </svg>
</template>
<script>
export default {
  name: 'MediumPriority',
};
</script>
<style scoped>
.st0 {
  fill: #ff9001;
}
.st1 {
  opacity: 0.6;
  fill: #c4c4c4;
}
</style>
