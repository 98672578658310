import axios from '../../plugins/axios.js';
const state = () => ({
  fetching: false,
  highTouchPoints: [],
});

const getters = {
  highTouchPoints: (state) => {
    return state.highTouchPoints;
  },
};

const actions = {
  async get(context) {
    // only query high touch points if we do not already have them.
    if (context.state.fetching !== true && context.state.highTouchPoints.length === 0) {
      context.commit('updateFetching', true);
      await axios.get('/api/v1/core/high-touch-points').then((response) => {
        context.commit('updateFetching', false);
        context.commit('updateHighTouchPoints', response.data.payload);
      });
    }

    return context.state.highTouchPoints;
  },
  getHighTouchPointsByCommonAreaType(context, id) {
    return axios.get(`/api/v1/core/high-touch-points/${id}`).then((response) => {
      return response.data.payload;
    });
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateHighTouchPoints(state, highTouchPoints) {
    state.highTouchPoints = highTouchPoints;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
