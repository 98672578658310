import { utils } from '../../utils/utils';
import axios from '../../plugins/axios.js';

const state = () => ({
  fetching: false,
  invoices: [],
  statuses: {
    draft: 'draft',
    invoiced: 'invoiced',
    paid: 'paid',
    uncollected: 'uncollected',
  },
  statusColor: {
    draft: '#5491F9',
    invoiced: '#31CF97',
    paid: '#96999C',
    uncollected: '#96999C',
  },
});

const getters = {
  invoices: (state) => {
    return state.invoices;
  },
  statuses: (state) => {
    return state.statuses;
  },
  statusColor: (state) => {
    return state.statusColor;
  },
  getStatusColor: (state) => (status) => {
    return state.statusColor[status];
  },
};

const actions = {
  getWithParams(context, params) {
    const queryString = utils.formatQueryString(params);

    return axios.get('/api/v1/special-project/invoices' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },
  getWithId(context, id) {
    return axios.get('/api/v1/special-project/invoice/' + id).then((response) => {
      return response.data.payload;
    });
  },
  getNextDraft(context, id) {
    return axios.get(`/api/v1/special-project/invoice/next-draft/${id}`).then((response) => {
      return response.data.payload;
    });
  },
  update(context, invoice) {
    return axios.put(`/api/v1/special-project/invoice/${invoice.id}`, invoice).then((response) => {
      return response.data.payload;
    });
  },
  delete(context, id) {
    return axios.delete(`/api/v1/special-project/invoice/${id}`).then((response) => {
      return response.data.payload;
    });
  },
  transition(context, params) {
    return axios
      .post(`/api/v1/special-project/invoice/${params.id}/transition`, {
        transition: params.transition,
      })
      .then((response) => {
        return response.data.payload;
      });
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateInvoices(state, invoices) {
    state.invoices = invoices;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
