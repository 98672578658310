import store from '../../store';

export default async ({ from }) => {
  // check if user object is set
  if (!store.state.user.user.id && from.name !== 'login') {
    // not logged in
    return {
      path: '/login',
    };
  }

  await store.dispatch('settings/loadSettings');
};
