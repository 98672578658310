import axios from '../../plugins/axios.js';
export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    getBuildingNotes(ctx, buildingId) {
      return axios.get('/api/v1/core/building/notes/' + buildingId).then((response) => {
        return response.data.payload;
      });
    },
    getBuildingNote(ctx, noteId) {
      return axios.get('/api/v1/core/building/note/' + noteId).then((response) => {
        return response.data.payload;
      });
    },
    saveBuildingNote(ctx, note) {
      return axios.post('/api/v1/core/building/note', note).then((response) => {
        return response.data.payload;
      });
    },
    deleteBuildingNote(ctx, noteId) {
      return axios.delete('/api/v1/core/building/note/' + noteId);
    },
  },
};
