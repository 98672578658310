import axios from '../../plugins/axios';

const state = () => ({
  fetching: false,
  areas: [],
});

const getters = {
  areas: (state) => {
    return state.areas;
  },
};

const actions = {
  getWithId(context, id) {
    return axios.get('/api/v1/core/areas/' + id).then((response) => {
      return response.data.payload;
    });
  },
  async get(context) {
    return axios.get('/api/v1/core/areas/table-data').then((response) => {
      context.commit('updateAreas', response.data.payload);
      return response.data.payload;
    });
  },
  save(ctx, area) {
    return axios.post('/api/v1/core/areas', area).then((response) => response.data.payload);
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateAreas(state, areas) {
    state.areas = areas;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
