export default () => {
  // check for token stored in a cookie
  const cookieName = 'cora-redirect-token';
  const cookieStr = `; ${document.cookie}`;
  const [, token = ''] = cookieStr.split(`; ${cookieName}=`);

  if (!token) {
    return {
      path: '/login',
    };
  }
};
