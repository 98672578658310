import axios from '../../plugins/axios.js';
const state = () => ({});

const getters = {};

const actions = {
  delete(context, id) {
    if (!id) {
      throw Error('file id is required when trying to delete a file.');
    }

    return axios.delete('/api/v1/core/files/' + id);
  },
  update(context, { id, name, comment }) {
    return axios
      .post(`/api/v1/core/file/${id}`, {
        name,
        comment,
      })
      .then((response) => {
        return response.data.payload;
      });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
