import axios from '../../plugins/axios';
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getBuildingTenants() {
      return axios.get(`/api/v1/core/building-tenants`).then((res) => res.data.payload);
    },
    createBuildingTenant(context, payload) {
      return axios.post(`/api/v1/core/building-tenants`, payload).then((res) => res.data.payload.tenant);
    },
    updateBuildingTenant(context, payload) {
      return axios.put(`/api/v1/core/building-tenants`, payload).then((res) => res.data.payload);
    },
    deleteBuildingTenant(context, id) {
      return axios.delete(`/api/v1/core/building-tenants/${id}`).then((res) => res.data.payload);
    },
  },
};
