<template>
  <login-layout>
    <template #title>
      <h1>{{ $t('reset password').capitalize() }}</h1>
    </template>

    <v-card
      elevation="10"
      class="my-12"
      :class="{ 'mx-4': $vuetify.display.smAndDown, 'mx-12': $vuetify.display.mdAndUp }"
    >
      <v-card-text>
        <div v-if="error" class="alert alert-success">
          {{ error }}
        </div>

        <v-text-field
          id="email"
          v-model="email"
          type="email"
          name="email"
          :label="$t('email').capitalize()"
          prepend-icon="mdi-email"
          :error-messages="errors.email"
          required
        />

        <v-text-field
          id="password"
          v-model="password"
          type="password"
          name="password"
          :label="$t('password').capitalize()"
          prepend-icon="mdi-account-lock"
          :error-messages="errors.password?.proper()"
          required
        />

        <v-text-field
          id="password-confirm"
          v-model="password_confirmation"
          type="password"
          name="password_confirmation"
          :label="$t('confirm password').capitalize()"
          prepend-icon="mdi-account-lock"
          required
        />
      </v-card-text>
    </v-card>
    <v-main class="login-section">
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn id="login-btn" rounded elevation="10" :loading="loading" variant="flat" @click="submit">
            {{ $t('reset password').capitalize() }}
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </login-layout>
</template>
<script>
import LoginLayout from '../components/LoginLayout.vue';
import router from '../router';
import axios from '../plugins/axios';

export default {
  components: { LoginLayout },
  data: () => ({
    loading: false,
    errors: {},
    error: null,
    token: null,
    email: null,
    password: null,
    password_confirmation: null,
  }),
  computed: {
    csrfToken() {
      return window.Laravel.csrfToken;
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(location.search);
    this.token = urlParams.get('token');
    this.email = urlParams.get('email');
  },
  methods: {
    submit() {
      this.loading = true;
      return axios
        .post('/password/reset', {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          router.push('/login');
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.error = error.response.data.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.login-section {
  text-align: center;
  margin-top: 50px;
}

#login-btn {
  width: 300px;
  height: 55px;
  color: white;
  background: linear-gradient(267.78deg, #ff9001 36.19%, #ff4200 116.03%);
  font-size: 20px;
}
</style>
