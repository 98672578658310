<template>
  <v-snackbar
    id="snackbar"
    v-model="snackbar"
    :timeout="duration"
    :color="color"
    location="top right"
    @click="clearSnackbar()"
  >
    <span v-html="text" />
  </v-snackbar>
</template>
<script>
import { event } from '../../utils/event';

export default {
  data() {
    return {
      snackbar: false,
      color: null,
      duration: 5000,
      text: null,
      cb: null,
      unsubscribe: null,
    };
  },
  mounted() {
    /**
     * Alert Event Listener
     */
    this.unsubscribe = event.on('alert', (message, duration, style, cb) => {
      if (!message) return;

      this.text = this.$t(message);
      if (duration) {
        this.alert.durations = duration;
      }

      if (style) {
        this.color = this.mapStyle(style);
      }

      if (cb) {
        this.cb = cb;
      }

      this.snackbar = true;
      if (this.cb) {
        this.cb();
      }
    });
  },
  beforeUnmount() {
    if (this.unsubscribe instanceof Function) {
      this.unsubscribe();
    }
  },
  methods: {
    clearSnackbar() {
      this.snackbar = false;
    },
    mapStyle(style) {
      let deprecatedStyles = {
        green: 'success',
        red: 'error',
      };

      if (deprecatedStyles[style]) {
        style = deprecatedStyles[style];
      }

      return style;
    },
  },
};
</script>
