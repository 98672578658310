const state = () => ({
  advancedSearch: false,
  advancedSearchData: {
    account_id: '',
    address: '',
    area_id: '',
    region_id: '',
    client_id: '',
  },
  filterQuery: null,
  onlyActive: true,
  taskViewDate: null,
  view: 'map',
});

const getters = {
  filters: (state) => {
    return {
      advancedSearch: state.advancedSearch,
      advancedSearchData: state.advancedSearchData,
      filterQuery: state.filterQuery,
      onlyActive: state.onlyActive,
      taskViewDate: state.taskViewDate,
      view: state.view,
    };
  },
};

const actions = {};

const mutations = {
  updateFilters(state, payload) {
    state.advancedSearch = payload.advancedSearch;
    state.advancedSearchData = { ...payload.advancedSearchData };
    state.filterQuery = payload.filterQuery;
    state.onlyActive = payload.onlyActive;
    state.taskViewDate = payload.taskViewDate;
    state.view = payload.view;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
