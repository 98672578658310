import { utils } from '../../utils/utils';
import axios from '../../plugins/axios';

const state = () => ({
  fetching: false,
  users: [],
});

const getters = {
  users: (state) => {
    return state.users;
  },
};

const actions = {
  async get(context) {
    context.commit('updateFetching', true);
    let users = [];
    try {
      const response = await axios.get('/api/v1/core/users');
      users = response.data.payload.sort((a, b) => a.name.localeCompare(b.name));
      context.commit('updateUsers', users);
    } finally {
      context.commit('updateFetching', false);
    }

    return users;
  },
  async getById({ state }, { userId, params }) {
    let user = state.users.find((u) => u.id === userId);
    if (!user || params) {
      const qs = utils.formatQueryString(params);
      user = await axios.get(`/api/v1/core/user/${userId}${qs}`).then((response) => response.data.payload);
    }

    return user;
  },
  deleteUser(context, userId) {
    return axios.delete(`/api/v1/admin/user/${userId}`);
  },
  restoreUser(context, userId) {
    return axios.put(`/api/v1/admin/user/${userId}/restore`);
  },
  getUserJobAssignments(ctx, { userId }) {
    return axios.get(`/api/v1/user/${userId}/job-assignments`).then((response) => response.data.payload);
  },
  deleteUserJobAssignment(ctx, { userId, buildingId, jobSiteAssignmentId }) {
    return axios.delete(`/api/v1/user/${userId}/job-site-assignment/${buildingId}/${jobSiteAssignmentId}`);
  },
  getUserRoles(ctx, { userId }) {
    return axios.get(`/api/v1/core/user/${userId}/roles`).then((response) => response.data.payload);
  },
  addUserRole(ctx, { userId, roleId }) {
    return axios.put(`/api/v1/core/user/${userId}/role/${roleId}`).then((response) => response.data.payload);
  },
  deleteUserRole(ctx, { userId, roleId }) {
    return axios.delete(`/api/v1/core/user/${userId}/role/${roleId}`).then((response) => response.data.payload);
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateUsers(state, users) {
    state.users = users;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
