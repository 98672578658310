import { utils } from '../../utils/utils';
import { event } from '../../utils/event';
import axios from '../../plugins/axios.js';

const defaultFilters = {
  building: 0,
  client: 0,
  region: 0,
  area: 0,
  type: 0,
  dateFrom: null,
  dateTo: null,
};

const state = () => ({
  statusColor: {
    new: '#5491F9',
    pending: '#31CF97',
    finalized: '#96999C',
  },
  statusCode: {
    new: 1,
    pending: 2,
    finalized: 3,
  },
  filters: defaultFilters,
});

const getters = {
  statusColor: (state) => {
    return state.statusColor;
  },
  statusCode: (state) => {
    return state.statusCode;
  },
  filters: (state) => {
    return state.filters;
  },
  getStatusColor: (state) => (code) => {
    const label = Object.entries(state.statusCode).filter((c) => c[1] === code)[0][0];
    return state.statusColor[label];
  },
};

const actions = {
  delete(context, reportId) {
    return axios.delete(`/api/v1/action-log-report/${reportId}`).catch((err) => {
      event.emit('alert', err.response.data.message, '', 'red');
      return null;
    });
  },

  deleteActionLog(context, id) {
    return axios.delete(`/api/v1/action-log/${id}`).catch((err) => {
      event.emit('alert', err.response.data.message, '', 'red');
      return null;
    });
  },

  deleteFinal(context, reportId) {
    return axios.delete(`/api/v1/action-log-report/finalize/delete/${reportId}`).catch((err) => {
      event.emit('alert', err.response.data.message, '', 'red');
      return null;
    });
  },

  finalize(context, reportId) {
    event.emit('loading', 'finalizing action log');
    return axios
      .post('/api/v1/action-log-report/finalize', {
        id: reportId,
      })
      .then((response) => {
        event.emit('loading', false);
        return response;
      })
      .catch((err) => {
        if (err.response?.data?.message) {
          event.emit('alert', err.response.data.message, '', 'red');
        } else {
          event.emit('alert', 'an unexpected error occurred', '', 'red');
          console.error(err);
        }
        return null;
      });
  },

  getActionLogs(context, reportId) {
    return axios.get(`/api/v1/action-log/table-data?id=${reportId}`).then((response) => {
      return response.data.payload;
    });
  },

  getById(context, id) {
    if (!id) {
      console.error('id is required when calling getById');
      return;
    }
    return axios.get(`/api/v1/action-log-report?id=${id}`).then((response) => {
      return response.data.payload;
    });
  },

  getWithParams(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/action-log-report' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },

  getTableData(context, params) {
    let queryString = '';
    if (params) {
      if (params.status) {
        params.status = Array.isArray(params.status) ? params.status.join(',') : params.status;
      }
      queryString = utils.formatQueryString(params);
    }
    return axios.get('/api/v1/action-log-reports/table-data' + encodeURI(queryString)).then((response) => {
      return response.data.payload;
    });
  },

  save(context, buildingId) {
    if (!buildingId) {
      console.error('building id is required when saving a day porter report.');
      return;
    }

    event.emit('loading', 'saving action log...');
    return axios
      .post('/api/v1/action-log-report/save', {
        id: buildingId,
      })
      .then((response) => {
        event.emit('loading', false);
        return response;
      });
  },

  saveActionLog(context, formData) {
    event.emit('loading', 'saving action log');
    return axios
      .post('/api/v1/action-log', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        event.emit('loading', false);
        return response;
      });
  },

  reopen(context, reportId) {
    event.emit('loading', 'reopening action log');
    return axios
      .post('/api/v1/action-log-report/reopen/' + reportId)
      .then((response) => {
        event.emit('loading', false);
        return response.data.payload;
      })
      .catch((err) => {
        event.emit('alert', err.response.data.payload.message, '', 'red');
        return null;
      });
  },
};

const mutations = {
  updateFilters(state, filters) {
    state.filters = filters;
  },
  resetFilters(state) {
    state.filters = defaultFilters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
