<template>
  <div id="confirm">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">{{ title?.capitalize() }}</v-card-title>
        <v-card-text>{{ contentHtml?.proper() }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <c-btn @click="respond(1)">{{ $t('agree')?.capitalize() }}</c-btn>
          <v-btn variant="text" rounded @click="respond(0)">{{ $t('disagree')?.capitalize() }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { event } from '../../utils/event';
import CBtn from './CoraPrimaryButton.vue';
export default {
  components: { CBtn },
  data() {
    return {
      dialog: false,
      callbackEvent: 'confirm-response',
      title: '',
      contentHtml: '',
      ok: 'Agree',
      cancel: 'Disagree',
      unsubscribe: null,
    };
  },

  mounted() {
    this.unsubscribe = event.on('confirm', (cbEvent, message) => {
      if (cbEvent) {
        this.callbackEvent = cbEvent;
      }

      this.contentHtml = message;
      this.dialog = true;
    });
  },

  beforeUnmount() {
    if (this.unsubscribe instanceof Function) {
      this.unsubscribe();
    }
  },

  methods: {
    respond(response) {
      this.dialog = false;
      event.emit(this.callbackEvent, response);
    },
  },
};
</script>
