import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import store from './store';
import { event } from './utils/event';
import moment from 'moment';
import { createApp, h } from 'vue';
import * as Sentry from '@sentry/vue';
import utils from './utils/utils';
import { DateTime, Settings } from 'luxon';

/**
 * Import styles
 */
import '../styles/app.scss';

// moment is deprecated and needs to be replaced by luxon
window.moment = moment;

// set default luxon settings
Settings.defaultLocale = 'en-US';

// extend JS String with custom functions
Object.defineProperties(String.prototype, {
  capitalize: {
    value() {
      return utils.capitalize(this);
    },
  },
  proper: {
    value() {
      return utils.proper(this);
    },
  },
  date: {
    value() {
      return DateTime.fromISO(this).toLocaleString(DateTime.DATE_SHORT);
    },
  },
  datetime: {
    value() {
      return DateTime.fromISO(this).toLocaleString(DateTime.DATETIME_SHORT);
    },
  },
});

// initialize the event bus
event.init();

const app = createApp({
  mounted() {
    this.$store.commit('breadcrumbs/setBreadcrumbs', null);
  },
  render: () => h(App),
});

/**
 * Add Sentry
 */
let releaseStage = 'development';
if (location.host === 'mycora.us') {
  releaseStage = 'production';
}
if (releaseStage === 'production') {
  // only init sentry in prod
  Sentry.init({
    app,
    environment: releaseStage,
    dsn: 'https://39f245345adf4e8895dbeaf10f86d92c@o1077177.ingest.sentry.io/6086178',
    ignoreErrors: ['AxiosError', 'Request failed with status code 403', 'Request failed with status code 404'],
  });
}

app.use(store);
app.use(i18n);
app.use(router);
app.use(vuetify);
app.mount('#app');

/**
 * Register workbox service worker
 */
// import { Workbox } from 'workbox-window';
//
// if ('serviceWorker' in navigator) {
//   const wb = new Workbox('/sw.js');
//
//   const showSkipWaitingPrompt = async () => {
//     wb.addEventListener('controlling', () => {
//       window.location.reload();
//     });
//
//     event.emit('newVersionAvailable');
//     event.on('updateNewVersion', () => {
//       wb.messageSkipWaiting();
//     });
//   };
//
//   // Add an event listener to detect when the registered
//   // service worker has installed but is waiting to activate.
//   wb.addEventListener('waiting', (event) => {
//     showSkipWaitingPrompt(event);
//   });
//
//   wb.register();
//   window.wb = wb;
// }
