import axios from '../../plugins/axios.js';
const state = () => ({
  organization: {
    logo: null,
    logo_horizontal: null,
    org_chart: null,
    primary_brand_color: null,
    secondary_brand_color: null,
  },
});

const getters = {
  logoUrl(state) {
    return state.organization?.logo?.url ?? '/images/cora.svg';
  },
  logoHorizontalUrl(state) {
    return state.organization?.logo_horizontal?.url ?? '/images/cora.svg';
  },
};

const actions = {
  async getOrg(ctx, orgSlug) {
    const response = await axios.get(`/api/v1/${orgSlug}/organization`);
    ctx.commit('setOrganization', response.data.payload);
    return response.data.payload;
  },
};

const mutations = {
  setOrganization(state, organization) {
    state.organization = organization;
  },
  setLogo(state, logo) {
    state.organization.logo = logo;
  },
  setOrgChart(state, file) {
    state.organization.org_chart = file;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
