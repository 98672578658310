<template>
  <v-snackbar v-model="visible" :timeout="-1">
    {{ $t('new app version available').capitalize() }}

    <template #actions>
      <v-btn variant="text" color="secondary" @click="upgradeVersion">{{ $t('update').capitalize() }}</v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { event } from '../utils/event';

export default {
  name: 'NewVersion',
  data() {
    return {
      visible: false,
    };
  },
  created() {
    event.on('newVersionAvailable', () => {
      this.visible = true;
    });
  },
  methods: {
    upgradeVersion() {
      event.emit('updateNewVersion');
    },
  },
};
</script>
