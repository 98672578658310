import axios from '../../plugins/axios.js';
const state = () => ({
  fetching: false,
  vendors: [],
});

const getters = {
  vendors: (state) => {
    return state.vendors;
  },
};

const actions = {
  get(context) {
    // only query areas if we do not already have them.
    if (context.state.fetching !== true && context.state.vendors.length === 0) {
      context.commit('updateFetching', true);
      return axios.get('/api/v1/core/vendors').then((response) => {
        context.commit('updateFetching', false);
        context.commit('updateVendors', response.data.payload);
      });
    }
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateVendors(state, vendors) {
    state.vendors = vendors;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
