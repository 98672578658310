import { utils } from '../../utils/utils';
import { event } from '../../utils/event';
import axios from '../../plugins/axios';

const defaultFilters = {
  building: 0,
  client: 0,
  region: 0,
  area: 0,
  type: 0,
  dateFrom: null,
  dateTo: null,
  inspectedBy: 0,
};

const state = () => ({
  inspections: [],
  reportTypes: [],
  statusColor: {
    pending: '#5491F9',
    completed: '#31CF97',
    finalized: '#96999C',
  },
  statusCode: {
    new: 1,
    pending: 2,
    completed: 4,
    finalized: 3,
  },
  filters: defaultFilters,
});

const getters = {
  reportTypes: (state) => {
    return state.reportTypes;
  },
  statusColor: (state) => {
    return state.statusColor;
  },
  statusCode: (state) => {
    return state.statusCode;
  },
  statusLabel: (state) => (code) => {
    return Object.entries(state.statusCode).filter((c) => c[1] === code)[0][0];
  },
  getStatusColor: (state) => (code) => {
    const label = Object.entries(state.statusCode).filter((c) => c[1] === code)[0][0];
    return state.statusColor[label];
  },
  filters: (state) => {
    return state.filters;
  },
};

const actions = {
  getWithId(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/quality/inspections/' + params.id + queryString).then((response) => {
      if (response.data.status === 'error') {
        event.emit('alert', response.data.payload.message, '', 'red');
        return null;
      }
      return response.data.payload;
    });
  },
  getWithParams(context, params) {
    let queryString = '';
    if (params.queryParams) {
      queryString = utils.formatQueryString(params.queryParams);
    }

    return axios.get('/api/v1/quality/inspections' + queryString).then((response) => {
      return response.data.payload;
    });
  },
  getTableData(context, params) {
    let queryString = '';
    if (params) {
      if (params.status) {
        params.status = Array.isArray(params.status) ? params.status.join(',') : params.status;
      }
      queryString = utils.formatQueryString(params);
    }
    return axios.get('/api/v1/quality/inspections/table-data' + queryString).then((response) => {
      return response.data.payload;
    });
  },
  getReportTypes({ commit }) {
    axios.get('/api/v1/quality/inspections/get-types').then((response) => {
      commit('updateReportTypes', response.data.payload);
    });
  },
  getShareLink(context, inspectionId) {
    return axios.get('/api/v1/quality/inspections/' + inspectionId + '/share-link').then((response) => {
      return response.data.payload.link;
    });
  },
};

const mutations = {
  updateReportTypes(state, types) {
    state.reportTypes = types;
  },
  updateFilters(state, filters) {
    state.filters = filters;
  },
  resetFilters(state) {
    state.filters = defaultFilters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
