import { utils } from '../../utils/utils';

const state = () => ({
  breadcrumbs: null,
});

const getters = {
  breadcrumbs: (state) => {
    return state.breadcrumbs;
  },
};

const actions = {};

const mutations = {
  setBreadcrumbs(state, breadcrumbs) {
    if (breadcrumbs) {
      breadcrumbs = breadcrumbs.map((b) => {
        b.title = utils.capitalize(b.title);
        return b;
      });
    }

    state.breadcrumbs = breadcrumbs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
