import axios from '../../plugins/axios.js';

const state = () => ({});

const getters = {};

const mutations = {};

const actions = {
  checkIntegrationIsEnabled(context, integrationId) {
    return axios.get(`/api/v1/integrations/${integrationId}/is-enabled`).then((response) => {
      return response.data.payload.enabled;
    });
  },

  getIntegrations() {
    return axios.get('/api/v1/integrations').then((response) => {
      return response.data.payload;
    });
  },

  getIntegration(ctx, id) {
    return axios.get(`/api/v1/integrations/${id}`).then((response) => {
      return response.data.payload;
    });
  },

  updateIntegration(ctx, { integrationId, settings }) {
    return axios.put(`/api/v1/integrations/${integrationId}/update`, { settings }).then((response) => {
      return response.data.payload;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
