<template>
  <login-layout>
    <template #title>
      <h1>{{ $t('reset password').capitalize() }}</h1>
    </template>

    <v-card
      elevation="10"
      class="my-12"
      :class="{ 'mx-4': $vuetify.display.smAndDown, 'mx-12': $vuetify.display.mdAndUp }"
    >
      <v-card-text>
        <div v-if="error" class="alert alert-success">
          {{ error.proper() }}
        </div>

        <v-text-field
          id="email"
          v-model="email"
          type="email"
          name="email"
          :label="$t('email').capitalize()"
          prepend-icon="mdi-email"
          :error-messages="errors.email"
          required
        />
      </v-card-text>
    </v-card>

    <v-main class="login-section">
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn id="login-btn" rounded elevation="10" :loading="loading" variant="flat" @click="submit">
            {{ $t('send reset link').capitalize() }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn class="forgot" variant="text" to="/login">
            {{ $t('login').capitalize() }}
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </login-layout>
</template>
<script>
import LoginLayout from '../components/LoginLayout.vue';
import axios from '../plugins/axios';

export default {
  components: { LoginLayout },
  data: () => ({
    loading: false,
    error: null,
    errors: {},
    email: null,
  }),
  computed: {
    csrfToken() {
      return window.Laravel.csrfToken;
    },
  },
  methods: {
    submit() {
      axios.skipReportError = true;
      return axios
        .post('/password/email', {
          email: this.email,
        })
        .then(() => {
          this.error = 'An email with a reset link has been sent to your inbox.';
          this.errors = {};
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.error = null;
            this.errors = error.response.data.errors;
          } else {
            this.errors = {};
            this.error = 'An email with a reset link has been sent to your inbox.';
          }
        });
    },
  },
};
</script>
<style scoped>
.welcome {
  text-align: center;
  margin-bottom: 50px;
}

h1,
h2 {
  font-weight: 400;
}

.login-section {
  text-align: center;
  margin-top: 50px;
}

#login-btn {
  width: 300px;
  height: 55px;
  color: white;
  background: linear-gradient(267.78deg, #ff9001 36.19%, #ff4200 116.03%);
  font-size: 20px;
}

.forgot {
  margin-top: 50px;
  font-size: 14px;
}

.v-card {
  padding: 44px 20px 30px 20px;
}

.v-text-field,
.v-checkbox {
  margin-top: 10px;
}

.v-application .black {
  background-color: #000000 !important;
  opacity: 0.84;
}

@media screen and (max-width: 600px) {
  #inner-card {
    margin: 5% 10% !important;
  }

  .v-card__text,
  .v-card__title {
    padding: 0;
  }

  .v-card__actions {
    padding: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  #inner-card {
    margin: 5% 10% !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1264px) {
  #inner-card {
    margin: 5% 10% !important;
  }
}
</style>
