import axios from '../../plugins/axios.js';
const state = () => ({
  fetching: false,
  questions: [],
});

const getters = {
  questions: (state) => {
    return state.questions;
  },
};

const actions = {
  async get(context) {
    // only query questions if we do not already have them.
    if (context.state.fetching !== true && context.state.questions.length === 0) {
      context.commit('updateFetching', true);
      await axios.get('/api/v1/quality/closet-inspection/get-questions').then((response) => {
        context.commit('updateFetching', false);
        context.commit('updateQuestions', response.data.payload);
      });
    }

    return context.state.questions;
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  updateQuestions(state, questions) {
    state.questions = questions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
