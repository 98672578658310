<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 15" fill="none">
    <path
      d="M5.27344 7.03125L6.44531 8.20312L9.08203 5.56641M10.489 2.92881C10.6096 3.22062 10.8412 3.45258 11.1328 3.57368L12.1554 3.99725C12.4472 4.11813 12.6791 4.35 12.8 4.64183C12.9208 4.93367 12.9208 5.26157 12.8 5.5534L12.3767 6.57528C12.2558 6.86724 12.2556 7.19547 12.3771 7.4873L12.7996 8.50886C12.8595 8.65341 12.8904 8.80834 12.8904 8.96481C12.8904 9.12128 12.8596 9.27623 12.7998 9.42079C12.7399 9.56535 12.6521 9.6967 12.5415 9.80732C12.4308 9.91795 12.2994 10.0057 12.1549 10.0655L11.133 10.4888C10.8412 10.6094 10.6093 10.8411 10.4882 11.1327L10.0646 12.1553C9.94374 12.4471 9.71189 12.679 9.42006 12.7999C9.12824 12.9207 8.80035 12.9207 8.50852 12.7999L7.48669 12.3766C7.19486 12.256 6.86709 12.2563 6.57543 12.3773L5.55287 12.8002C5.2612 12.9208 4.9336 12.9207 4.64201 12.8C4.35043 12.6792 4.1187 12.4476 3.99774 12.1561L3.57405 11.1332C3.4534 10.8413 3.2218 10.6094 2.93018 10.4883L1.90762 10.0647C1.61592 9.94389 1.38413 9.71218 1.26321 9.42051C1.14229 9.12884 1.14213 8.80109 1.26276 8.5093L1.68601 7.48743C1.80659 7.19559 1.80635 6.8678 1.68533 6.57614L1.26268 5.5528C1.20276 5.40826 1.1719 5.25333 1.17188 5.09686C1.17185 4.94038 1.20266 4.78544 1.26253 4.64088C1.32241 4.49632 1.41018 4.36497 1.52084 4.25435C1.6315 4.14372 1.76287 4.05599 1.90744 3.99615L2.92928 3.57288C3.22082 3.45233 3.45263 3.22102 3.57381 2.92972L3.99737 1.90712C4.11825 1.61528 4.3501 1.38342 4.64193 1.26254C4.93375 1.14165 5.26164 1.14165 5.55347 1.26254L6.5753 1.68581C6.86713 1.80639 7.1949 1.80615 7.48656 1.68512L8.50955 1.26319C8.80134 1.14238 9.12916 1.1424 9.42093 1.26326C9.71269 1.38412 9.94451 1.61592 10.0654 1.90767L10.4891 2.93058L10.489 2.92881Z"
      stroke="black"
      stroke-width="1.17188"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'CheckVerified',
};
</script>
