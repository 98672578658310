<template>
  <div class="fill-height bg-black">
    <v-row align="center" justify="center" class="ma-0 fill-height">
      <v-col cols="12" sm="8" md="6" lg="5" xl="4">
        <v-card>
          <div class="cora">
            <v-img class="mb-6 mt-3" src="/images/cora.svg" height="44" />
          </div>
          <div v-if="!hideTitle" class="welcome">
            <slot name="title">
              <h1>{{ $t('welcome!') }}</h1>
              <h2>{{ $t("we're glad to see you") }}</h2>
            </slot>
          </div>
          <slot></slot>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.welcome {
  text-align: center;
  margin-bottom: 50px;
}

h1,
h2 {
  font-weight: 400;
}

.login-section {
  text-align: center;
  margin-top: 50px;
}

#login-btn {
  width: 300px;
  height: 55px;
  color: white;
  background: linear-gradient(267.78deg, #ff9001 36.19%, #ff4200 116.03%);
  font-size: 20px;
}

.forgot {
  margin-top: 50px;
  font-size: 20px;
}

.v-card {
  padding: 44px 20px 30px 20px;
}

.v-text-field,
.v-checkbox {
  margin-top: 10px;
}

.v-application .black {
  background-color: #000000 !important;
  opacity: 0.84;
}

@media screen and (max-width: 600px) {
  #inner-card {
    margin: 5% 10% !important;
  }

  .v-card__text,
  .v-card__title {
    padding: 0;
  }

  .v-card__actions {
    padding: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  #inner-card {
    margin: 5% 10% !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1264px) {
  #inner-card {
    margin: 5% 10% !important;
  }
}
</style>
