import Emitter from 'tiny-emitter';

const event = {
  bus: null,

  init() {
    if (!this.bus) {
      this.bus = new Emitter();
    }

    return this;
  },

  emit(name, ...args) {
    this.bus.emit(name, ...args);
    return this;
  },

  on(event, callback) {
    this.bus.on(event, callback);

    return () => this.bus.off(event, callback);
  },

  off(event, callback) {
    this.bus.off(event, callback);
    return this;
  },
};

export { event };
