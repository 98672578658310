import axios from '../../plugins/axios';
const state = () => ({});

const getters = {};

const mutations = {};

const actions = {
  sync: async (ctx, { userId, buildingIds }) => {
    return axios.put(`/api/v1/user/${userId}/job-site-access`, { buildingIds });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
