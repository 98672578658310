import axios from '../../plugins/axios';

const state = () => ({
  fetching: false,
  types: [],
});

const getters = {
  types: (state) => {
    return state.types;
  },
};

const actions = {
  get(context) {
    // only query areas if we do not already have them.
    if (context.state.fetching !== true && context.state.types.length === 0) {
      context.commit('updateFetching', true);
      axios.get('/api/v1/core/background-types').then((response) => {
        context.commit('updateFetching', false);
        context.commit('update', response.data.payload);
      });
    }
  },
};

const mutations = {
  updateFetching(state, booleanValue) {
    state.fetching = booleanValue;
  },

  update(state, types) {
    state.types = types;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
