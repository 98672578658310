// src/plugins/vuetify.js
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { aliases } from 'vuetify/iconsets/mdi';

import HighPriorityIcon from '../components/icons/HighPriority.vue';
import MediumPriorityIcon from '../components/icons/MediumPriority.vue';
import LowPriorityIcon from '../components/icons/LowPriority.vue';
import Tool from '../components/icons/Tool.vue';
import CheckVerified from '../components/icons/CheckVerified.vue';

const opts = {
  theme: {
    defaultTheme: 'cora',
    themes: {
      cora: {
        dark: false,
        colors: {
          primary: '#999999',
          secondary: '#FF9001',
          'on-secondary': '#fff',
          accent: '#5491f9',
          error: '#FF5252',
          info: '#2196F3',
          success: '#31cf97',
          warning: '#FFC107',
        },
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    aliases: {
      ...aliases,
      checkVerified: CheckVerified,
      highPriority: HighPriorityIcon,
      mediumPriority: MediumPriorityIcon,
      lowPriority: LowPriorityIcon,
      tool: Tool,
    },
  },
  defaults: {
    VCard: {
      variant: 'outlined',
      style: 'border: 1px solid rgba(0,0,0,.12)',
      class: 'bg-white',
    },
  },
};

export default createVuetify(opts);
