import axios from 'axios';
import { event } from '../utils/event';
import * as Sentry from '@sentry/vue';
import router from '../router';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
};

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login' });
        return Promise.reject(error);
      }

      return;
    }

    if (axios.skipReportError) {
      return Promise.reject(error);
    }

    // check for errors array
    let errorMessage = error;
    if (error.response?.data?.payload?.errors) {
      errorMessage = error.response.data.payload.errors.join('<br>');
    } else if (error.response?.data?.payload?.messages) {
      errorMessage = error.response.data.payload.messages.join('. ');
    } else if (error.response?.data?.payload?.message) {
      errorMessage = error.response.data.payload.message;
    } else if (typeof error.response?.data?.payload === 'string') {
      errorMessage = error.response.data.payload;
    } else if (error.response?.data?.message) {
      errorMessage = error.response.data.message;
    }

    event.emit('alert', errorMessage, '', 'red');
    Sentry.addBreadcrumb({
      category: 'request.error',
      message: errorMessage,
      level: 'error',
      data: error,
    });

    return Promise.reject(error);
  }
);

export default axios;
